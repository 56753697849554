import React, { Component } from 'react';
import classnames from 'classnames';

import Cookies from 'universal-cookie';

import Accordion from './Accordion';
import CookieGroup from './CookieGroup';

export default class CookieCategory extends Component {
    constructor(props) {
        super(props);

        this.onHandleCheckbox = this.onHandleCheckbox.bind(this);
    }

    onHandleCheckbox() {
        const { cookie_reference } = this.props.category;
        const cookies = new Cookies();

        const hasCookieSet = cookies.get(cookie_reference);

        if (hasCookieSet == 'true') {
            cookies.set(cookie_reference, false, { path: '/' });
        } else {
            cookies.set(cookie_reference, true, { path: '/' });
        }
        this.props.onChange();
    }

    renderSwitch() {
        const { cookie_reference, necessary } = this.props.category;
        const cookies = new Cookies();

        const hasCookieSet =
            cookies.get(cookie_reference) == 'true' ? true : false;

        if (necessary) {
            return <div className="bmw-eprivacy-switch -on">Always on</div>;
        }

        return (
            <label className="bmw-eprivacy-switch">
                <input
                    type="checkbox"
                    defaultChecked={hasCookieSet}
                    onChange={this.onHandleCheckbox}
                />
                <span className="bmw-eprivacy-slider bmw-eprivacy-slider--round" />
            </label>
        );
    }

    _renderHeader() {
        return <p>Cookies and options in detail</p>;
    }

    _renderBody() {
        const { groups, description } = this.props.category;
        return (
            <React.Fragment>
                <span dangerouslySetInnerHTML={{ __html: description }} />

                {groups.map((group, idx) => {
                    return (
                        <CookieGroup
                            group={group}
                            key={`cookie-group-${idx}`}
                        />
                    );
                })}
            </React.Fragment>
        );
    }

    render() {
        const { name, usage, cookie_reference } = this.props.category;

        return (
            <div
                className="bmw-eprivacy-section -category"
                id={`bmw-eprivacy-${cookie_reference}`}
            >
                <div>
                    <h2>{name}</h2>
                    {this.renderSwitch()}
                </div>
                <span dangerouslySetInnerHTML={{ __html: usage }} />

                <Accordion
                    header={this._renderHeader()}
                    body={this._renderBody()}
                />
            </div>
        );
    }
}
