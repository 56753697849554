/* global document, window */
import React from 'react';
import Cookies from 'universal-cookie';

import CookiesDetails from './components/CookiesDetails';
import { getCookieData } from './utils/axios';
import { emitEvent } from './utils/cookies';

class CookieControllerContainer extends React.Component {
    constructor(props) {
        super(props);

        // Set the component's initial state.
        this.state = {
            cookieData: false,
        };
    }

    // ------------------------------------
    // Event Functions
    // ------------------------------------
    componentWillMount = () => {
        const { client, api } = this.props;

        const cookies = new Cookies();
        cookies.set(process.env.BANNER_COOKIE_KEY, true, { path: '/' });

        if (cookieData) return;

        const url = api ? api : `${process.env.COOKIE_API}/${client}/`;

        const cookieData = getCookieData(
            url,
            client,
            this.onCookieSuccess,
            this.onCookieError
        );

        this.setState({ cookieData });

        // Output a logging event to notify that the ePrivacy controller has initialised.
        console.log('ePrivacy controller initialised.');
    };

    onCookieSuccess = (response) => {
        this.setState({ cookieData: response.data });

        emitEvent('eprivacy_initialized', response.data);
    };

    onCookieError = (error) => {
        console.log('There was an error retrieving cookies.', error);
    };

    // ------------------------------------
    // Public Functions
    // ------------------------------------
    render() {
        return (
            <div id="bmw-eprivacy-app-root" className="bmw-eprivacy">
                <CookiesDetails data={this.state.cookieData} />
            </div>
        );
    }
}

export default CookieControllerContainer;
