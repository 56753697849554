import React, { Component } from 'react';

export default class CookieBanner extends Component {
    constructor(props) {
        super(props);

        console.log('cookie banner constructor');
    }

    // ------------------------------------
    // Event Functions
    // ------------------------------------
    // Automatically called whenever the user clicks onto the find out more call to action.
    onClickFindOutMore = () => {
        this.props.rejectCookies();

        // Redirect the user to the platform's banner cookie link.
        window.location = this.props.data.banner_cookie_link;
    };

    // ------------------------------------
    // Public Functions
    // ------------------------------------
    render() {
        return (
            <div className="bmw-eprivacy-banner">
                <div className="container">
                    <div className="bmw-eprivacy-banner__container">
                        <div id="bmw-eprivacy-banner__message">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.props.data.banner_content,
                                }}
                            />

                            <div
                                className="bmw-eprivacy-banner__ctas"
                                id="bmw-eprivacy-banner__cta"
                            >
                                <button
                                    className="btn primary btn-primary"
                                    type="button"
                                    onClick={this.props.acceptCookies}
                                >
                                    Accept
                                </button>

                                <button
                                    className="btn primary btn-primary"
                                    type="button"
                                    onClick={this.onClickFindOutMore}
                                >
                                    Find out more including how to reject
                                    cookies.
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
