/* global document, window */
import React from 'react';
import Cookies from 'universal-cookie';

import CookieBanner from './components/CookieBanner';
import { getCookieData } from './utils/axios';
import { emitEvent } from './utils/cookies';

export default class CookieBannerContainer extends React.Component {
    constructor(props) {
        super(props);

        // Set the component's initial state.
        this.state = {
            cookieData: false,
            showBanner: false,
        };
    }

    // ------------------------------------
    // Event Functions
    // ------------------------------------
    componentWillMount = () => {
        const { client, api } = this.props;
        const url = api ? api : `${process.env.COOKIE_API}/${client}/`;

        getCookieData(url, client, this.onCookieSuccess, this.onCookieError);
    };

    onAcceptAllCookies = () => {
        const { cookieData } = this.state;

        const cookies = new Cookies();
        cookies.set(process.env.BANNER_COOKIE_KEY, true, { path: '/' });

        cookieData.cookie_list.forEach((cookie) => {
            if (cookie.necessary) return;

            cookies.set(cookie.cookie_reference, true, { path: '/' });
        });

        this.setState({ showBanner: false });

        emitEvent('eprivacy_accepted', cookieData);
    };

    onCookieSuccess = (response) => {
        const isAccepted = this._checkIfAccepted();

        emitEvent('eprivacy_initialized', response.data);

        this.setState({ cookieData: response.data, showBanner: !isAccepted });

        // Output a logging event to notify that the ePrivacy banner has initialised.
        console.log('ePrivacy banner initialised.');
    };

    onCookieError = (error) => {
        console.log('There was an error retrieving cookies.', error);
    };

    onRejectAllCookies = () => {
        const cookies = new Cookies();
        cookies.set(process.env.BANNER_COOKIE_KEY, true, { path: '/' });

        this.setState({ showBanner: false });
    };

    // ------------------------------------
    // Public Functions
    // ------------------------------------
    render() {
        return (
            <div id="bmw-eprivacy-banner-root" className="bmw-eprivacy">
                {this._renderCookieBanner()}
            </div>
        );
    }

    // ------------------------------------
    // Private Functions
    // ------------------------------------
    // Returns a boolean as to whether or not the user has seen the cookie banner and accepted cookies.
    _checkIfAccepted() {
        const cookies = new Cookies();
        const hasCookieSet = cookies.get(process.env.BANNER_COOKIE_KEY);

        return hasCookieSet;
    }

    // Conditionally renders the component's cookie banner.
    _renderCookieBanner() {
        const { cookieData, showBanner } = this.state;

        if (!showBanner) return;

        return (
            <CookieBanner
                data={cookieData}
                acceptCookies={this.onAcceptAllCookies}
                rejectCookies={this.onRejectAllCookies}
            />
        );
    }
}
